import { Select } from 'antd'
import { isBGM } from 'common/config/acl'
import useACL from 'hooks/useACL'
import useDebounce from 'hooks/useDebounce'
import useGetDataOwnerShip from 'hooks/useGetDataOwnerShip'
import useGetWarehouseID from 'hooks/useGetWarehouseIDs'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'hooks/usePaging'
import { Distributor } from 'interfaces/distributor'
import { Warehouse } from 'interfaces/warehouse'
import { uniqBy } from 'lodash'
import { useTranslations } from 'next-intl'
import { stringify } from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import {
  convertToArray,
  defaultStringifyOption,
  getResponseData,
} from 'utils/commonUtils'
import { DISTRIBUTOR_TYPE_MASTER_DISTRIBUTOR_HUB } from 'utils/distributor'

interface IProps {
  disabled?: boolean
  optionValueKey?: string
  value?: any
  multiple?: boolean
  onChange?: (id: string, options: any) => void
  placeholder?: string
  isGetHub?: boolean
  filters?: any
}

const DistributorSelect: React.FC<IProps> = ({
  disabled,
  optionValueKey = 'id',
  multiple = false,
  value,
  onChange,
  placeholder,
  isGetHub = false,
  filters,
}) => {
  const [loading, setLoading] = useState(false)
  const [distributors, setDistributors] = useState<Distributor[]>([])
  const { warehouseIDs } = useGetWarehouseID()
  const { userRoles } = useACL()
  const [searchTerm, setSearchTerm] = useState<string>()
  const translate = useTranslations()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const { warehouseOwnerShip } = useGetDataOwnerShip(!filters)

  const isRoleBGM = isBGM(userRoles)

  const getDistributors = async (name?: string) => {
    try {
      setLoading(true)
      const params = stringify(
        {
          page: DEFAULT_PAGE,
          perPage: DEFAULT_PAGE_SIZE,
          orderBy: 'updatedAt desc',
          warehouseIDs: warehouseOwnerShip?.map((item) => item?.id),
          ...filters,
          ...(isGetHub && {
            type: DISTRIBUTOR_TYPE_MASTER_DISTRIBUTOR_HUB,
            isActive: true,
          }),
          name,
        },
        {
          encode: false,
          skipNulls: true,
        }
      )
      const response = await axiosInstance.get(`/v1/distributors?${params}`)
      setDistributors(getResponseData<Distributor[]>(response))
    } catch (error) {
      setDistributors([])
    } finally {
      setLoading(false)
    }
  }

  const getWarehouses = async (ids: string[]) => {
    try {
      setLoading(true)
      const params = stringify(
        { ids, includeDistributor: true },
        defaultStringifyOption
      )
      const res = await axiosInstance.get(`/v1/warehouses?${params}`)
      const data = getResponseData<Warehouse[]>(res)

      const dataDistributors = uniqBy(
        data?.map(({ distributor }) => distributor),
        'id'
      )

      setDistributors(dataDistributors)
      setLoading(false)
    } catch (error) {
      setDistributors([])
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isRoleBGM && warehouseIDs?.length > 0 && !distributors.length) {
      getWarehouses(warehouseIDs)
    }
    if (!isRoleBGM) {
      debouncedSearchTerm
        ? getDistributors(debouncedSearchTerm)
        : getDistributors()
    }
  }, [
    debouncedSearchTerm,
    isRoleBGM,
    warehouseIDs,
    warehouseOwnerShip,
    filters,
  ])

  const getDistributorByID = useCallback(async () => {
    const promises =
      multiple &&
      convertToArray(value)?.map((i) => {
        return axiosInstance.get(`/v1/distributors/${i}`).catch((err) => err)
      })

    const res: any =
      optionValueKey === 'id' && !multiple
        ? await axiosInstance.get(`/v1/distributors/${value}`)
        : await Promise.all(promises)

    const data =
      optionValueKey === 'id' && !multiple
        ? getResponseData<any>(res as any)
        : res?.map((i) => i?.data?.data)

    if (data) {
      if (multiple) {
        const nextItems = distributors.filter((dis) => {
          return data.find(({ id }) => id === dis.id)
        })

        if (nextItems.length !== data?.length) {
          setDistributors([...distributors, ...data])
        }
      } else {
        const nextItems = distributors.filter((dis) => {
          return dis.id === data.id
        })

        if (nextItems.length === 0) {
          setDistributors([...distributors, data])
        }
      }
    }
  }, [distributors, multiple, value])

  useEffect(() => {
    const existing =
      multiple && value?.length > 0
        ? distributors.find((i) => value?.includes(i.id))
        : distributors.find((i) => i.id === value)
    if (
      ((value && !existing && !multiple) ||
        (multiple && !existing && value?.length > 0)) &&
      distributors?.length
    ) {
      getDistributorByID()
    }
  }, [value, multiple, distributors])

  return (
    <Select
      mode={multiple ? 'multiple' : undefined}
      allowClear
      showSearch
      value={value}
      disabled={disabled}
      loading={loading}
      showArrow={false}
      className="w-full"
      placeholder={placeholder || translate('common.selectDistributor')}
      onBlur={() => setSearchTerm(undefined)}
      onChange={onChange}
      onClear={() => setSearchTerm(undefined)}
      onDeselect={() => setSearchTerm(undefined)}
      onSearch={setSearchTerm}
      filterOption={
        isRoleBGM
          ? (input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : false
      }
      maxTagCount="responsive"
    >
      {distributors?.map((p) => (
        <Select.Option key={p.id} value={p[optionValueKey]}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default React.memo(DistributorSelect)
